"use strict";
exports.__esModule = true;
exports.PTZTokensDeprecated = exports.PTZTokensNewer = exports.PTZTokensElevation = exports.PTZTokensGrid = exports.PTZTokensBorder = exports.PTZTokensBlur = exports.PTZTokensOpacity = exports.PTZTokensSpacing = exports.PTZTokensShadow = exports.PTZTokensColor = exports.PTZTokensFont = exports.PTZTokensElevationConfigList = exports.PTZTokensGridConfigList = exports.PTZTokensBorderConfigList = exports.PTZTokensBlurConfigList = exports.PTZTokensOpacityConfigList = exports.PTZTokensSpacingConfigList = exports.PTZTokensShadowConfigList = exports.PTZTokensColorConfigList = exports.PTZTokensFontConfigList = exports.PTZTokensElevationConfig = exports.PTZTokensGridConfig = exports.PTZTokensBorderConfig = exports.PTZTokensBlurConfig = exports.PTZTokensOpacityConfig = exports.PTZTokensSpacingConfig = exports.PTZTokensShadowConfig = exports.PTZTokensColorConfig = exports.PTZTokensFontConfig = void 0;
var PTZTokensFontConfig;
(function (PTZTokensFontConfig) {
    PTZTokensFontConfig["FamilyBase"] = "family-base";
    PTZTokensFontConfig["WeightRegular"] = "weight-regular";
    PTZTokensFontConfig["FontNormal"] = "font-normal";
    PTZTokensFontConfig["WeightBold"] = "weight-bold";
    PTZTokensFontConfig["FontBold"] = "font-bold";
    PTZTokensFontConfig["WeightBlack"] = "weight-black";
    PTZTokensFontConfig["FontBlack"] = "font-black";
    PTZTokensFontConfig["WeightExtraBlack"] = "weight-extra-black";
    PTZTokensFontConfig["FontExtraBlack"] = "font-extra-black";
    PTZTokensFontConfig["LineheightLg"] = "lineheight-lg";
    PTZTokensFontConfig["LineheightMd"] = "lineheight-md";
    PTZTokensFontConfig["LineheightXs"] = "lineheight-xs";
    PTZTokensFontConfig["LineheightSm"] = "lineheight-sm";
    PTZTokensFontConfig["Size4xs"] = "size-4xs";
    PTZTokensFontConfig["Size3xs"] = "size-3xs";
    PTZTokensFontConfig["Size2xs"] = "size-2xs";
    PTZTokensFontConfig["SizeXs"] = "size-xs";
    PTZTokensFontConfig["SizeSm"] = "size-sm";
    PTZTokensFontConfig["SizeMd"] = "size-md";
    PTZTokensFontConfig["SizeLg"] = "size-lg";
    PTZTokensFontConfig["SizeXl"] = "size-xl";
    PTZTokensFontConfig["Size2xl"] = "size-2xl";
    PTZTokensFontConfig["Size3xl"] = "size-3xl";
    PTZTokensFontConfig["SizeMax"] = "size-max";
})(PTZTokensFontConfig = exports.PTZTokensFontConfig || (exports.PTZTokensFontConfig = {}));
var PTZTokensColorConfig;
(function (PTZTokensColorConfig) {
    PTZTokensColorConfig["PrimaryBrand"] = "primary-brand";
    PTZTokensColorConfig["PrimaryDark"] = "primary-dark";
    PTZTokensColorConfig["PrimaryLight"] = "primary-light";
    PTZTokensColorConfig["PrimarySignature"] = "primary-signature";
    PTZTokensColorConfig["PrimaryBlackFriday"] = "primary-black-friday";
    PTZTokensColorConfig["SecondaryBrand"] = "secondary-brand";
    PTZTokensColorConfig["SecondaryDark"] = "secondary-dark";
    PTZTokensColorConfig["SecondaryLight"] = "secondary-light";
    PTZTokensColorConfig["SecondaryBlackFriday"] = "secondary-black-friday";
    PTZTokensColorConfig["NeutralBlack"] = "neutral-black";
    PTZTokensColorConfig["NeutralDarkerAccent"] = "neutral-darker-accent";
    PTZTokensColorConfig["NeutralDark"] = "neutral-dark";
    PTZTokensColorConfig["NeutralMid"] = "neutral-mid";
    PTZTokensColorConfig["NeutralLight"] = "neutral-light";
    PTZTokensColorConfig["NeutralLighterAccent"] = "neutral-lighter-accent";
    PTZTokensColorConfig["NeutralWhite"] = "neutral-white";
    PTZTokensColorConfig["SupYellowDarkerAccent"] = "sup-yellow-darker-accent";
    PTZTokensColorConfig["SupYellowDark"] = "sup-yellow-dark";
    PTZTokensColorConfig["SupYellowMid"] = "sup-yellow-mid";
    PTZTokensColorConfig["SupYellowLight"] = "sup-yellow-light";
    PTZTokensColorConfig["SupGreenDarkerAccent"] = "sup-green-darker-accent";
    PTZTokensColorConfig["SupGreenDark"] = "sup-green-dark";
    PTZTokensColorConfig["SupGreenMid"] = "sup-green-mid";
    PTZTokensColorConfig["SupGreenLight"] = "sup-green-light";
    PTZTokensColorConfig["SupRedDarkerAccent"] = "sup-red-darker-accent";
    PTZTokensColorConfig["SupRedDark"] = "sup-red-dark";
    PTZTokensColorConfig["SupRedMid"] = "sup-red-mid";
    PTZTokensColorConfig["SupRedLight"] = "sup-red-light";
    PTZTokensColorConfig["ChartPurpleDark"] = "chart-purple-dark";
    PTZTokensColorConfig["ChartPurpleMid"] = "chart-purple-mid";
    PTZTokensColorConfig["ChartPurpleLight"] = "chart-purple-light";
    PTZTokensColorConfig["ChartPinkDark"] = "chart-pink-dark";
    PTZTokensColorConfig["ChartPinkMid"] = "chart-pink-mid";
    PTZTokensColorConfig["ChartPinkLight"] = "chart-pink-light";
    PTZTokensColorConfig["ChartBlueDark"] = "chart-blue-dark";
    PTZTokensColorConfig["ChartBlueMid"] = "chart-blue-mid";
    PTZTokensColorConfig["ChartBlueLight"] = "chart-blue-light";
    PTZTokensColorConfig["ChartOrangeDark"] = "chart-orange-dark";
    PTZTokensColorConfig["ChartOrangeMid"] = "chart-orange-mid";
    PTZTokensColorConfig["ChartOrangeLight"] = "chart-orange-light";
    PTZTokensColorConfig["CategoryDog"] = "category-dog";
    PTZTokensColorConfig["CategoryReptile"] = "category-reptile";
    PTZTokensColorConfig["CategoryCat"] = "category-cat";
    PTZTokensColorConfig["CategoryBird"] = "category-bird";
    PTZTokensColorConfig["CategoryFish"] = "category-fish";
    PTZTokensColorConfig["CategoryCactus"] = "category-cactus";
    PTZTokensColorConfig["CategoryMouse"] = "category-mouse";
    PTZTokensColorConfig["CategoryRabbit"] = "category-rabbit";
    PTZTokensColorConfig["ClubzPrimaryBrand"] = "clubz-primary-brand";
    PTZTokensColorConfig["ClubzPrimaryLight"] = "clubz-primary-light";
    PTZTokensColorConfig["ClubzSecondaryBrand"] = "clubz-secondary-brand";
    PTZTokensColorConfig["ClubzSecondaryDark"] = "clubz-secondary-dark";
    PTZTokensColorConfig["ClubzOffwhite"] = "clubz-offwhite";
})(PTZTokensColorConfig = exports.PTZTokensColorConfig || (exports.PTZTokensColorConfig = {}));
var PTZTokensShadowConfig;
(function (PTZTokensShadowConfig) {
    PTZTokensShadowConfig["ShadowTopSm"] = "top-sm";
    PTZTokensShadowConfig["ShadowSm"] = "sm";
    PTZTokensShadowConfig["ShadowMd"] = "md";
    PTZTokensShadowConfig["ShadowLg"] = "lg";
    PTZTokensShadowConfig["ShadowXl"] = "xl";
})(PTZTokensShadowConfig = exports.PTZTokensShadowConfig || (exports.PTZTokensShadowConfig = {}));
var PTZTokensSpacingConfig;
(function (PTZTokensSpacingConfig) {
    PTZTokensSpacingConfig["Spacing4xs"] = "4xs";
    PTZTokensSpacingConfig["Spacing3xs"] = "3xs";
    PTZTokensSpacingConfig["Spacing2xs"] = "2xs";
    PTZTokensSpacingConfig["SpacingXs"] = "xs";
    PTZTokensSpacingConfig["SpacingSm"] = "sm";
    PTZTokensSpacingConfig["SpacingMd"] = "md";
    PTZTokensSpacingConfig["SpacingLg"] = "lg";
    PTZTokensSpacingConfig["SpacingXl"] = "xl";
    PTZTokensSpacingConfig["Spacing2xl"] = "2xl";
    PTZTokensSpacingConfig["Spacing3xl"] = "3xl";
    PTZTokensSpacingConfig["Spacing4xl"] = "4xl";
    PTZTokensSpacingConfig["Spacing5xl"] = "5xl";
    PTZTokensSpacingConfig["Spacing6xl"] = "6xl";
    PTZTokensSpacingConfig["Spacing7xl"] = "7xl";
    PTZTokensSpacingConfig["Spacing8xl"] = "8xl";
})(PTZTokensSpacingConfig = exports.PTZTokensSpacingConfig || (exports.PTZTokensSpacingConfig = {}));
var PTZTokensOpacityConfig;
(function (PTZTokensOpacityConfig) {
    PTZTokensOpacityConfig["OpacityXs"] = "xs";
    PTZTokensOpacityConfig["OpacitySm"] = "sm";
    PTZTokensOpacityConfig["OpacityMd"] = "md";
    PTZTokensOpacityConfig["OpacityLg"] = "lg";
    PTZTokensOpacityConfig["OpacityXl"] = "xl";
})(PTZTokensOpacityConfig = exports.PTZTokensOpacityConfig || (exports.PTZTokensOpacityConfig = {}));
var PTZTokensBlurConfig;
(function (PTZTokensBlurConfig) {
    PTZTokensBlurConfig["BlurSm"] = "sm";
    PTZTokensBlurConfig["BlurMd"] = "md";
    PTZTokensBlurConfig["BlurLg"] = "lg";
})(PTZTokensBlurConfig = exports.PTZTokensBlurConfig || (exports.PTZTokensBlurConfig = {}));
var PTZTokensBorderConfig;
(function (PTZTokensBorderConfig) {
    PTZTokensBorderConfig["BorderNone"] = "none";
    PTZTokensBorderConfig["BorderSm"] = "sm";
    PTZTokensBorderConfig["BorderMd"] = "md";
    PTZTokensBorderConfig["BorderLg"] = "lg";
    PTZTokensBorderConfig["BorderXl"] = "xl";
})(PTZTokensBorderConfig = exports.PTZTokensBorderConfig || (exports.PTZTokensBorderConfig = {}));
var PTZTokensGridConfig;
(function (PTZTokensGridConfig) {
    PTZTokensGridConfig["GridCol3xl"] = "col-3xl";
    PTZTokensGridConfig["GridCol2xl"] = "col-2xl";
    PTZTokensGridConfig["GridGridColXxl"] = "grid-col-xxl";
    PTZTokensGridConfig["GridColXl"] = "col-xl";
    PTZTokensGridConfig["GridColLg"] = "col-lg";
    PTZTokensGridConfig["GridColMd"] = "col-md";
    PTZTokensGridConfig["GridMargin3xl"] = "margin-3xl";
    PTZTokensGridConfig["GridMargin2xl"] = "margin-2xl";
    PTZTokensGridConfig["GridGridMarginXxl"] = "grid-margin-xxl";
    PTZTokensGridConfig["GridMarginXl"] = "margin-xl";
    PTZTokensGridConfig["GridMarginLg"] = "margin-lg";
    PTZTokensGridConfig["GridMarginMd"] = "margin-md";
    PTZTokensGridConfig["GridMarginSm"] = "margin-sm";
})(PTZTokensGridConfig = exports.PTZTokensGridConfig || (exports.PTZTokensGridConfig = {}));
var PTZTokensElevationConfig;
(function (PTZTokensElevationConfig) {
    PTZTokensElevationConfig["Elevation0"] = "0";
    PTZTokensElevationConfig["Elevation1"] = "1";
    PTZTokensElevationConfig["Elevation2"] = "2";
    PTZTokensElevationConfig["Elevation3"] = "3";
    PTZTokensElevationConfig["Elevation4"] = "4";
    PTZTokensElevationConfig["Elevation5"] = "5";
})(PTZTokensElevationConfig = exports.PTZTokensElevationConfig || (exports.PTZTokensElevationConfig = {}));
exports.PTZTokensFontConfigList = {
    FamilyBase: 'family-base',
    WeightRegular: 'weight-regular',
    FontNormal: 'font-normal',
    WeightBold: 'weight-bold',
    FontBold: 'font-bold',
    WeightBlack: 'weight-black',
    FontBlack: 'font-black',
    WeightExtraBlack: 'weight-extra-black',
    FontExtraBlack: 'font-extra-black',
    LineheightLg: 'lineheight-lg',
    LineheightMd: 'lineheight-md',
    LineheightXs: 'lineheight-xs',
    LineheightSm: 'lineheight-sm',
    Size4xs: 'size-4xs',
    Size3xs: 'size-3xs',
    Size2xs: 'size-2xs',
    SizeXs: 'size-xs',
    SizeSm: 'size-sm',
    SizeMd: 'size-md',
    SizeLg: 'size-lg',
    SizeXl: 'size-xl',
    Size2xl: 'size-2xl',
    Size3xl: 'size-3xl',
    SizeMax: 'size-max'
};
exports.PTZTokensColorConfigList = {
    PrimaryBrand: 'primary-brand',
    PrimaryDark: 'primary-dark',
    PrimaryLight: 'primary-light',
    PrimarySignature: 'primary-signature',
    PrimaryBlackFriday: 'primary-black-friday',
    SecondaryBrand: 'secondary-brand',
    SecondaryDark: 'secondary-dark',
    SecondaryLight: 'secondary-light',
    SecondaryBlackFriday: 'secondary-black-friday',
    NeutralBlack: 'neutral-black',
    NeutralDarkerAccent: 'neutral-darker-accent',
    NeutralDark: 'neutral-dark',
    NeutralMid: 'neutral-mid',
    NeutralLight: 'neutral-light',
    NeutralLighterAccent: 'neutral-lighter-accent',
    NeutralWhite: 'neutral-white',
    SupYellowDarkerAccent: 'sup-yellow-darker-accent',
    SupYellowDark: 'sup-yellow-dark',
    SupYellowMid: 'sup-yellow-mid',
    SupYellowLight: 'sup-yellow-light',
    SupGreenDarkerAccent: 'sup-green-darker-accent',
    SupGreenDark: 'sup-green-dark',
    SupGreenMid: 'sup-green-mid',
    SupGreenLight: 'sup-green-light',
    SupRedDarkerAccent: 'sup-red-darker-accent',
    SupRedDark: 'sup-red-dark',
    SupRedMid: 'sup-red-mid',
    SupRedLight: 'sup-red-light',
    ChartPurpleDark: 'chart-purple-dark',
    ChartPurpleMid: 'chart-purple-mid',
    ChartPurpleLight: 'chart-purple-light',
    ChartPinkDark: 'chart-pink-dark',
    ChartPinkMid: 'chart-pink-mid',
    ChartPinkLight: 'chart-pink-light',
    ChartBlueDark: 'chart-blue-dark',
    ChartBlueMid: 'chart-blue-mid',
    ChartBlueLight: 'chart-blue-light',
    ChartOrangeDark: 'chart-orange-dark',
    ChartOrangeMid: 'chart-orange-mid',
    ChartOrangeLight: 'chart-orange-light',
    CategoryDog: 'category-dog',
    CategoryReptile: 'category-reptile',
    CategoryCat: 'category-cat',
    CategoryBird: 'category-bird',
    CategoryFish: 'category-fish',
    CategoryCactus: 'category-cactus',
    CategoryMouse: 'category-mouse',
    CategoryRabbit: 'category-rabbit',
    ClubzPrimaryBrand: 'clubz-primary-brand',
    ClubzPrimaryLight: 'clubz-primary-light',
    ClubzSecondaryBrand: 'clubz-secondary-brand',
    ClubzSecondaryDark: 'clubz-secondary-dark',
    ClubzOffwhite: 'clubz-offwhite'
};
exports.PTZTokensShadowConfigList = {
    ShadowTopSm: 'top-sm',
    ShadowSm: 'sm',
    ShadowMd: 'md',
    ShadowLg: 'lg',
    ShadowXl: 'xl'
};
exports.PTZTokensSpacingConfigList = {
    Spacing4xs: '4xs',
    Spacing3xs: '3xs',
    Spacing2xs: '2xs',
    SpacingXs: 'xs',
    SpacingSm: 'sm',
    SpacingMd: 'md',
    SpacingLg: 'lg',
    SpacingXl: 'xl',
    Spacing2xl: '2xl',
    Spacing3xl: '3xl',
    Spacing4xl: '4xl',
    Spacing5xl: '5xl',
    Spacing6xl: '6xl',
    Spacing7xl: '7xl',
    Spacing8xl: '8xl'
};
exports.PTZTokensOpacityConfigList = {
    OpacityXs: 'xs',
    OpacitySm: 'sm',
    OpacityMd: 'md',
    OpacityLg: 'lg',
    OpacityXl: 'xl'
};
exports.PTZTokensBlurConfigList = {
    BlurSm: 'sm',
    BlurMd: 'md',
    BlurLg: 'lg'
};
exports.PTZTokensBorderConfigList = {
    BorderNone: 'none',
    BorderSm: 'sm',
    BorderMd: 'md',
    BorderLg: 'lg',
    BorderXl: 'xl'
};
exports.PTZTokensGridConfigList = {
    GridCol3xl: 'col-3xl',
    GridCol2xl: 'col-2xl',
    GridGridColXxl: 'grid-col-xxl',
    GridColXl: 'col-xl',
    GridColLg: 'col-lg',
    GridColMd: 'col-md',
    GridMargin3xl: 'margin-3xl',
    GridMargin2xl: 'margin-2xl',
    GridGridMarginXxl: 'grid-margin-xxl',
    GridMarginXl: 'margin-xl',
    GridMarginLg: 'margin-lg',
    GridMarginMd: 'margin-md',
    GridMarginSm: 'margin-sm'
};
exports.PTZTokensElevationConfigList = {
    Elevation0: '0',
    Elevation1: '1',
    Elevation2: '2',
    Elevation3: '3',
    Elevation4: '4',
    Elevation5: '5'
};
exports.PTZTokensFont = [
    'family-base',
    'weight-regular',
    'font-normal',
    'weight-bold',
    'font-bold',
    'weight-black',
    'font-black',
    'weight-extra-black',
    'font-extra-black',
    'lineheight-lg',
    'lineheight-md',
    'lineheight-xs',
    'lineheight-sm',
    'size-4xs',
    'size-3xs',
    'size-2xs',
    'size-xs',
    'size-sm',
    'size-md',
    'size-lg',
    'size-xl',
    'size-2xl',
    'size-3xl',
    'size-max',
];
exports.PTZTokensColor = [
    'primary-brand',
    'primary-dark',
    'primary-light',
    'primary-signature',
    'primary-black-friday',
    'secondary-brand',
    'secondary-dark',
    'secondary-light',
    'secondary-black-friday',
    'neutral-black',
    'neutral-darker-accent',
    'neutral-dark',
    'neutral-mid',
    'neutral-light',
    'neutral-lighter-accent',
    'neutral-white',
    'sup-yellow-darker-accent',
    'sup-yellow-dark',
    'sup-yellow-mid',
    'sup-yellow-light',
    'sup-green-darker-accent',
    'sup-green-dark',
    'sup-green-mid',
    'sup-green-light',
    'sup-red-darker-accent',
    'sup-red-dark',
    'sup-red-mid',
    'sup-red-light',
    'chart-purple-dark',
    'chart-purple-mid',
    'chart-purple-light',
    'chart-pink-dark',
    'chart-pink-mid',
    'chart-pink-light',
    'chart-blue-dark',
    'chart-blue-mid',
    'chart-blue-light',
    'chart-orange-dark',
    'chart-orange-mid',
    'chart-orange-light',
    'category-dog',
    'category-reptile',
    'category-cat',
    'category-bird',
    'category-fish',
    'category-cactus',
    'category-mouse',
    'category-rabbit',
    'clubz-primary-brand',
    'clubz-primary-light',
    'clubz-secondary-brand',
    'clubz-secondary-dark',
    'clubz-offwhite',
];
exports.PTZTokensShadow = [
    'top-sm',
    'sm',
    'md',
    'lg',
    'xl',
];
exports.PTZTokensSpacing = [
    '4xs',
    '3xs',
    '2xs',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    '8xl',
];
exports.PTZTokensOpacity = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
];
exports.PTZTokensBlur = [
    'sm',
    'md',
    'lg',
];
exports.PTZTokensBorder = [
    'none',
    'sm',
    'md',
    'lg',
    'xl',
];
exports.PTZTokensGrid = [
    'col-3xl',
    'col-2xl',
    'grid-col-xxl',
    'col-xl',
    'col-lg',
    'col-md',
    'margin-3xl',
    'margin-2xl',
    'grid-margin-xxl',
    'margin-xl',
    'margin-lg',
    'margin-md',
    'margin-sm',
];
exports.PTZTokensElevation = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
];
exports.PTZTokensNewer = [
    'shadowTopSm',
    'breakpoint3xl',
    'breakpoint2xl',
    'gridCol2xl',
    'gridMargin3xl',
    'gridMargin2xl',
    'gap3xl',
    'gap2xl',
    'fontWeightRegular',
    'fontWeightBold',
    'fontWeightBlack',
    'fontWeightExtraBlack',
    'fontLineheightLg',
    'fontLineheightMd'
];
exports.PTZTokensDeprecated = [
    'breakpointXxl',
    'gridColXxl',
    'gridMarginXxl',
    'gapXxl',
    'fontNormal',
    'fontBold',
    'fontBlack',
    'fontExtraBlack'
];
var PTZTokens = {
    spacing4xs: '0.125rem',
    spacing3xs: '0.25rem',
    spacing2xs: '0.5rem',
    spacingXs: '0.75rem',
    spacingSm: '1rem',
    spacingMd: '1.5rem',
    spacingLg: '2rem',
    spacingXl: '2.5rem',
    spacing2xl: '3rem',
    spacing3xl: '3.5rem',
    spacing4xl: '4rem',
    spacing5xl: '4.5rem',
    spacing6xl: '5rem',
    spacing7xl: '5.5rem',
    spacing8xl: '6rem',
    colorPrimaryBrand: '#175EA8',
    colorPrimaryDark: '#123F6D',
    colorPrimaryLight: '#00A1E4',
    colorPrimarySignature: '#CCE5F3',
    colorPrimaryBlackFriday: '#1A2C3F',
    colorSecondaryBrand: '#F9E6B3',
    colorSecondaryDark: '#D6CDAB',
    colorSecondaryLight: '#FDF9F4',
    colorSecondaryBlackFriday: '#F9E6B3',
    colorNeutralBlack: '#1D1D1D',
    colorNeutralDarkerAccent: '#454545',
    colorNeutralDark: '#747474',
    colorNeutralMid: '#D5D5D5',
    colorNeutralLight: '#EEEEEE',
    colorNeutralLighterAccent: '#F5F5F5',
    colorNeutralWhite: '#FFFFFF',
    colorSupYellowDarkerAccent: '#B28E0B',
    colorSupYellowDark: '#F1C115',
    colorSupYellowMid: '#FFCD19',
    colorSupYellowLight: '#FFEEB3',
    colorSupGreenDarkerAccent: '#24803A',
    colorSupGreenDark: '#34AD51',
    colorSupGreenMid: '#39BC58',
    colorSupGreenLight: '#D9ECE3',
    colorSupRedDarkerAccent: '#C62015',
    colorSupRedDark: '#EB4A3F',
    colorSupRedMid: '#FF5045',
    colorSupRedLight: '#FFB7B3',
    colorChartPurpleDark: '#6842A7',
    colorChartPurpleMid: '#8B56E1',
    colorChartPurpleLight: '#ECE0FF',
    colorChartPinkDark: '#C800B4',
    colorChartPinkMid: '#DF58D1',
    colorChartPinkLight: '#FFE5FB',
    colorChartBlueDark: '#0590CE',
    colorChartBlueMid: '#82C7E7',
    colorChartBlueLight: '#EFFBFF',
    colorChartOrangeDark: '#C5570E',
    colorChartOrangeMid: '#FF6F0F',
    colorChartOrangeLight: '#FFE7D8',
    colorCategoryDog: '#E8604B',
    colorCategoryReptile: '#71B790',
    colorCategoryCat: '#D19F72',
    colorCategoryBird: '#DD6288',
    colorCategoryFish: '#8E7FAE',
    colorCategoryCactus: '#999C5F',
    colorCategoryMouse: '#8E9CA6',
    colorCategoryRabbit: '#72BAD1',
    colorClubzPrimaryBrand: '#174B80',
    colorClubzPrimaryLight: '#E2F0F3',
    colorClubzSecondaryBrand: '#F1D3A9',
    colorClubzSecondaryDark: '#966540',
    colorClubzOffwhite: '#FDF9F5',
    borderNone: '0rem',
    borderSm: '0.0625rem',
    borderMd: '0.125rem',
    borderLg: '0.25rem',
    borderXl: '0.5rem',
    rounded2xs: '0.25rem',
    roundedXs: '0.5rem',
    roundedSm: '0.75rem',
    roundedMd: '1rem',
    roundedLg: '1.25rem',
    roundedXl: '1.5rem',
    rounded2xl: '2rem',
    roundedMax: '15.625rem',
    shadowTopSm: '0rem -0.5rem 1rem rgba(0, 0, 0, 0.08)',
    shadowSm: '0rem 0.5rem 1rem rgba(0, 0, 0, 0.08)',
    shadowMd: '0rem 1rem 2rem rgba(0, 0, 0, 0.08)',
    shadowLg: '0rem 2rem 4rem rgba(0, 0, 0, 0.15)',
    shadowXl: '0rem 3rem 6rem rgba(0, 0, 0, 0.15)',
    breakpoint3xl: '1920px',
    breakpoint2xl: '1400px',
    breakpointXxl: '1400px',
    breakpointXl: '1200px',
    breakpointLg: '992px',
    breakpointMd: '768px',
    breakpointSm: '576px',
    gridCol3xl: '7.75rem',
    gridCol2xl: '4.5rem',
    gridColXxl: '4.5rem',
    gridColXl: '4rem',
    gridColLg: '5.5rem',
    gridColMd: '4rem',
    gridMargin3xl: '2.5rem',
    gridMargin2xl: '3rem',
    gridMarginXxl: '3rem',
    gridMarginXl: '2.5rem',
    gridMarginLg: '2rem',
    gridMarginMd: '1rem',
    gridMarginSm: '1rem',
    gap3xl: '2rem',
    gap2xl: '2.5rem',
    gapXxl: '2.5rem',
    gapXl: '2rem',
    gapLg: '2rem',
    gapMd: '2rem',
    gapSm: '1.5rem',
    opacityXs: '0.08',
    opacitySm: '0.16',
    opacityMd: '0.32',
    opacityLg: '0.64',
    opacityXl: '0.72',
    blurSm: '0.25rem',
    blurMd: '0.5rem',
    blurLg: '1rem',
    fontFamilyBase: 'CircularXX',
    fontWeightRegular: '400',
    fontNormal: '400',
    fontWeightBold: '700',
    fontBold: '700',
    fontWeightBlack: '900',
    fontBlack: '900',
    fontWeightExtraBlack: '950',
    fontExtraBlack: '950',
    fontLineheightLg: '132%',
    fontLineheightMd: '124%',
    fontLineheightXs: '108%',
    fontLineheightSm: '116%',
    fontSize4xs: '0.625rem',
    fontSize3xs: '0.75rem',
    fontSize2xs: '0.875rem',
    fontSizeXs: '1rem',
    fontSizeSm: '1.125rem',
    fontSizeMd: '1.25rem',
    fontSizeLg: '1.5rem',
    fontSizeXl: '1.75rem',
    fontSize2xl: '2rem',
    fontSize3xl: '3rem',
    fontSizeMax: '4rem',
    elevation0: '0',
    elevation1: '5',
    elevation2: '10',
    elevation3: '15',
    elevation4: '20',
    elevation5: '25'
};
exports["default"] = PTZTokens;
